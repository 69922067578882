<template>
  <div class="customer-cell" @click="to(data)">
    <van-row>
      <van-col span="4">
        <div class="circle">
          <span v-if="data.customerName">{{ data.enterpriseType.substring(0, 1) }}</span>
        </div>
      </van-col>
      <van-col span="15" class="text van-ellipsis title">{{ data.customerName }}</van-col>
      <van-col span="5" class="tag"
               :style="{color: data.customerRelationshipname==='初期客户' ? '#F59D31' :  data.customerRelationshipname == '中期客户' ? '#7BABED' : '#1AAD19'}">
        <span v-if="data.customerRelationshipname">{{ data.customerRelationshipname }}</span>
      </van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="20" class="text van-ellipsis info">
        客户类型：{{ data.enterpriseType }}
      </van-col>
    </van-row>
    <van-row>
      <van-col span="4">
        <div class="circle1">
          <span v-if="data.customerGrade">{{ data.customerGrade.substring(0, 1) }}</span>
        </div>
      </van-col>
      <van-col offset="0" span="12" class="text van-ellipsis info">
        企业性质：{{ data.enterpriseNature }}
      </van-col>
      <van-col offset="2" span="6">
        <div
            class="slide" @click.stop="claimCustomer(data)">
             <span class='classification'>
                     认领客户
                  </span>
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col offset="4" span="20" class="text van-ellipsis user">
        负责人：{{ data.customerGm }}
      </van-col>

    </van-row>

  </div>
</template>

<script>

import {customerManage} from "../api/myCustomer";
import {Toast} from "vant";

export default {
  name: "CustomerCell",
  methods: {
    to(data) {
      if (data.enterpriseType.substring(0, 1) == '货') {
        this.$router.push('/CustomerListEdit/' + data.crmCustomerid);
      } else if (data.enterpriseType.substring(0, 1) == '船') {
        this.$router.push('/CustomerListEditCF/' + data.crmCustomerid);
      } else if (data.enterpriseType.substring(0, 1) == '港') {
        this.$router.push('/CustomerListEditGF/' + data.crmCustomerid);
      } else {
        this.$router.push('/CustomerListEdit/' + data.crmCustomerid);
      }
    },
    claimCustomer(data) {
      this.$emit("claimCus", data);
    },

  },
  data() {
    return {
      lv: ["初级", "中级", "深入合作"],
    }
  },
  props: {
    data: Object
  }
}
</script>

<style scoped>
.customer-cell {
  background: #ffffff;
  margin-top: 4px;
}

.customer-cell .notice-swipe {
  height: 40px;
  line-height: 40px;
}

.customer-cell .circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #3B71BE;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(0%, 0%);
}

.customer-cell .circle1 {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #cbb633;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(0%, 0%);
}

.customer-cell .circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.customer-cell .circle span {
  display: inline-block;
  vertical-align: middle;
}

.customer-cell .text {
  text-align: left;
}

.customer-cell .title {
  font-weight: 400;
  font-size: 15px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .info {
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .user {
  font-weight: 400;
  font-size: 14px;
  color: #318EF5;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .tag {
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .customerRelationshipname {
  font-weight: 100;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0;
  line-height: 10px;
  text-decoration: none;
  color: darkseagreen;
}


.customer-cell .slide {
  flex: none;
  width: auto;
  text-align: left;
}

.customer-cell .classification {
  float: left;
  margin-left: 10px;
  padding: 10px;
  background: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 14px;
}

</style>

<style>
.van-list .customer-cell .van-row:first-child {
  padding-top: 20px;
}
</style>
