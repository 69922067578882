<template>
  <div class="customer-list">
    <div style="background-color: white">
      <!-- 搜索框-->
      <van-row>
        <van-col span="5" style="margin-top: 20px;padding-left: 10px">
          <i @click="reverseAll()" :class="`el-icon-${isAll ? 'collection-tag' : 'collection-tag'}`"
             :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '13px'}">全部</i>
          <i @click="reverseAll()" :class="`el-icon-${isAll ? 'arrow-down' : 'arrow-up'}`"
             :style="{color: isAll ? '#3B71BE' : 'BLACK', fontSize: '15px'}"></i>
        </van-col>
        <van-col span="19">
          <van-search v-model="selectCustomerValue" :focus="theFocus" custom-class="inp" placeholder="请输入搜索关键词"
                      show-action
                      @cancel="onCancel()" @search="onRefresh()"/>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="7" offset="1">
          <el-select v-model="lxxz" placeholder="类型选择">
            <el-option
                v-for="item in lxxz_list"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </van-col>

        <van-col span="11" offset="1" class="rowCount">
          <!--        <span>当前查询条数：{{ listRow }}</span>-->
        </van-col>
<!--        <van-col span="5" style="text-align: right;">-->
<!--          <i @click="reverse()" :class="`el-icon-${isArrow ? 'finished' : 'finished'}`"-->
<!--             :style="{color: isArrow ? '#3B71BE' : 'BLACK', fontSize: '15px', paddingRight: '13px', marginTop: '10px'}">排序</i>-->
<!--        </van-col>-->
      </van-row>
    </div>
    <!-- 添加按钮-->
    <Add to="/CustomerListAdd/" :param=Math.random().toString(36).slice(2)></Add>
    <!-- 列表-->
    <div ref="fresh" :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model="loading"
              loading-text="加载中"
              :finished="finished"
              finished-text="没有更多了"
              @load="nextPage"
              style="margin-bottom: 75px"
          >
            <customer-cell @claimCus="claimCusName" v-for="(item,index) in list" v-bind:key="item.id" :data="item"/>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <van-dialog
        v-model="claim"
        :title=claimCus.customerName
        show-cancel-button
        :beforeClose="claimBtn"
        confirm-button-text='是'
        cancel-button-text='否'
    >
      <van-row>
        <van-col>
          <van-field v-model="reason"
                     label="认领原因"
                     type="textarea"
                     rows="3"
                     autosize
                     input-align="left"
                     left-icon="smile-o"
                     :placeholder=placeholder />

        </van-col>
      </van-row>

    </van-dialog>
    <customer-list-select
        title="多条件选择"
        message="多条件选择"
        :showDialog="isShowDialog"
        @sendmsg='getMsg'
        @closeDialog='closeDialog'></customer-list-select>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import CustomerCell from '@/components/CustomerCell'
import Add from '@/components/Add';
import CustomerListSelect from "@/components/CustomerListSelect/index"
import {customerManage, checkGetCustomer} from '../../api/myCustomer.js'
import {Toast} from 'vant';

export default {
  name: "CustomerList",
  components: {CrmTabBar, CustomerCell, Add, CustomerListSelect, Toast},
  data() {
    return {
      lxxz: '00',
      lxxz_list: [
        {text: '无负责人', value: '00'},
        {text: '全部客户', value: '01'},
        {text: '其他客户', value: '02'},
      ],
      claimCus: {
        customerName: '',
        crmCustomerid: ''
      },
      placeholder: '1.我司业务/对接人调整\n2.客户业务调整\n3.其他',
      reason: '',
      claim: false,
      //查询条数
      listRow: '',
      theFocus: null,
      isShowDialog: false,
      isAll: false,
      //排序
      isArrow: false,
      //结果集
      list: [],
      //客户搜索框
      selectCustomerValue: '',
      //列表高度
      freshHeight: 0,
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      //多条件筛选
      selectList: {
        EnterpriseType: [
          {lable: '', value: '', id: ''},
        ],
        EnterpriseNature: [
          {lable: '', value: '', id: ''},
        ],
        EnterpriseLevel: [
          {lable: '', value: '', id: ''},
        ],
      }
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.onRefresh()
  },
  watch: {
    //监听公司名头
    'selectCustomerValue': function () {
      this.list = [];
      this.onRefresh();
    },
    //监听排序
    'isArrow': function () {
      this.list = [];
      this.onRefresh();
    },
    //监听类型选择
    'lxxz': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  methods: {
    claimCusName(e) {
      console.log(e)
      this.claimCus.customerName = e.customerName
      this.claimCus.crmCustomerid = e.crmCustomerid
      checkGetCustomer({
        fzr: this.$store.getters.userId,
        crmCustomerid: this.claimCus.crmCustomerid
      }).then(res => {
        if(res.length>0){
          this.$toast({
            message: '您已经认领该客户，请等待运管中心批复！',
            position: 'center'
          });
        }else{
          this.claim = true
        }
      }).catch(err => {
        console.log(err)
      });
    },
    claimBtn(action, done) {
      if (action === 'confirm') {
        if (this.reason == '') {
          Toast('请填写认领原因')
          done(false)
        } else {
          const now = new Date();
          let currentTime = now.getFullYear() + '年' + (now.getMonth() + 1) + '月' + now.getDate() + '日' + now.toLocaleTimeString();
          let description = '于' + currentTime + '认领客户：' + this.claimCus.customerName
          customerManage({
            fzr: this.$store.getters.userId,
            customerName: this.claimCus.customerName,
            crmCustomerid: this.claimCus.crmCustomerid,
            type: '02',
            tranFzr: '',
            reason: this.reason,
            description: description,
          }).then(res => {
            done()
            Toast.success('已推送至运管中心，请等待推送通知！');
          }).catch(err => {
            console.log(err)
          });
        }
      } else if (action === 'cancel') {
        done()
      }
    },
    reverse() {
      this.isArrow = !this.isArrow
    },
    reverseAll() {
      this.isAll = !this.isAll
      this.isShowDialog = true;
    },
    getMsg(data) {
      this.isAll = !this.isAll
      this.isShowDialog = false;
      this.selectList = {};
      this.selectList.EnterpriseType = data.EnterpriseType
      this.selectList.EnterpriseNature = data.EnterpriseNature
      this.selectList.EnterpriseLevel = data.EnterpriseLevel
      console.log(data)
      this.onRefresh();
    },
    closeDialog() {
      this.isShowDialog = false;
      this.isAll = !this.isAll;
      this.onCancel();
    },
    onCancel() {
      this.selectList = {
        EnterpriseType: [
          {lable: '', value: '', id: ''},
        ],
        EnterpriseNature: [
          {lable: '', value: '', id: ''},
        ],
        EnterpriseLevel: [
          {lable: '', value: '', id: ''},
        ],
      }
      this.onRefresh();
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 下拉刷新
    getData() {
      this.loading = true
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getBulkCrmCustomertwoList.json',
          {
            enterpriseType: this.selectList.EnterpriseType.lable == null ? '' : this.selectList.EnterpriseType.lable,
            enterpriseNature: this.selectList.EnterpriseNature.lable == null ? '' : this.selectList.EnterpriseNature.lable,
            customerRelationshipname: this.selectList.EnterpriseLevel.lable == null ? '' : this.selectList.EnterpriseLevel.lable,
            customerName: this.selectCustomerValue,
            userNo: this.$store.getters.userId,
            addressProvince: '',
            isArrow: this.isArrow,
            current: this.currentPage,
            size: this.pageSize,
            type: this.lxxz,
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          console.log(res.data)
          res.data.forEach(item => {
            this.list.push(item)
          })
          this.listRow = this.list.length
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.data.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    }
  }
}

</script>

<style scoped>
.customer-list {
  /*background-color: white*/
}

.rowCount {
  font-size: 14px;
  text-align: left;
}

.opa-fade-enter-active,
.opa-fade-leave-active {
  transition: all 0.5s ease;
}

.opa-fade-enter-from,
.opa-fade-leave-to {
  opacity: 0;
}


</style>
<style>
.customer-list .el-input__inner {
  border: none;
}
</style>